/* eslint-disable ssr-friendly/no-dom-globals-in-module-scope */
/* eslint-disable no-console */
import 'styles/fonts/typography.css'
import 'the-new-css-reset/css/reset.css'

import * as amplitude from '@amplitude/analytics-browser'
import Layout from 'components/Layout'
import Providers from 'components/Providers'
import { LayoutProvider } from 'contexts/LayoutContext'
import gsap from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import ScrollSmoother from 'gsap/ScrollSmoother'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, CSSPlugin)

export const onClientEntry = () => {
  // Initialize Amplitude
  amplitude.init('e030f74e57e32609e0c7123d8c65fb27')
}

export const onInitialClientRender = () => {
  const exludedPaths = ['/launch/']
  const pathname = window.location.pathname
  if (exludedPaths.includes(pathname)) {
    return
  } else {
    // Add Default scripts
    const inlineDefaultScript = document.createElement('script')
    inlineDefaultScript.type = 'text/javascript'
    inlineDefaultScript.text = `
    window.__default__ = { team_id: 195 };
  `
    document.head.append(inlineDefaultScript)
    const externalDefaultScript = document.createElement('script')
    externalDefaultScript.src = 'https://import-cdn.default.com/v2/index.js'
    externalDefaultScript.type = 'text/javascript'
    externalDefaultScript.async = true
    document.head.append(externalDefaultScript)
  }
}

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => {
  return <Providers>{element}</Providers>
}

export const wrapPageElement = ({ element }: { element: React.ReactNode }) => {
  return (
    <LayoutProvider>
      <Layout>{element}</Layout>
    </LayoutProvider>
  )
}

gsap.config({
  nullTargetWarn: false,
})
