import { EarlyAccessContext } from 'components/Providers/EarlyAccessContext'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import useAnimation from 'library/useAnimation'
import useMedia from 'library/useMedia'
import { useContext, useState } from 'react'

/**
 * deterministically track the position of the header logo
 */
export const useLogoPosition = (dropdownIsOpen: boolean) => {
  const { open: earlyAccessOpen } = useContext(EarlyAccessContext)

  const [scrollY, setScrollY] = useState(0)

  useAnimation(() => {
    ScrollTrigger.create({
      onUpdate: () => {
        setScrollY(ScrollSmoother.get()?.scrollTop() ?? 0)
      },
    })
  }, [])

  const earlyAccess = useMedia('normal', 'normal', 'hidden', 'hidden')
  const dropdown = useMedia<'dropdown' | false>(false, false, false, 'dropdown')

  // first! if the early access modal is open
  if (earlyAccessOpen) {
    return earlyAccess
  }

  // if we're on the page, use the dropdown value if applicable, else depend on the scroll position
  if (dropdownIsOpen && dropdown) return dropdown

  return scrollY < 2 ? 'normal' : 'hidden'
}
