import UniversalLink from 'components/UniversalLink'
import { ReactComponent as ArrowSVG } from 'images/global/buttonArrow.svg'
import { ReactComponent as AmbientIcon } from 'images/product/icons/ambient-intelligence.svg'
import { ReactComponent as CollaborativeIcon } from 'images/product/icons/collaborative-planning.svg'
import { ReactComponent as IntuitiveModelingIcon } from 'images/product/icons/intuitive-modeling.svg'
import { ReactComponent as ReportsIcon } from 'images/product/icons/reports.svg'
import { fresponsive } from 'library/fullyResponsive'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import colors from 'styles/colors'
import media from 'styles/media'
import textStyles from 'styles/text'

export default function Link({
  children,
  className,
  to,
}: {
  children: ReactNode
  className: string
  to: string
}) {
  return (
    <Wrapper className={className} to={to}>
      <Arrow />
      {children}
    </Wrapper>
  )
}

export function ProductDropdownLink({
  children,
  className,
}: {
  children: ReactNode
  className: string
}) {
  return (
    <DropdownWrapper className={className}>
      <Arrow />
      {children}
      <DropdownMenu>
        <DropdownItem $hoverColor={colors.blue400} to="/product/collaborative-planning">
          <CollaborativeIcon />
          <span>Collaborative Planning</span>
        </DropdownItem>
        <DropdownItem $hoverColor={colors.green400} to="/product/intuitive-modeling">
          <IntuitiveModelingIcon />
          <span>Intuitive Modeling</span>
        </DropdownItem>
        <DropdownItem $hoverColor={colors.purple400} to="/product/reporting">
          <ReportsIcon />
          <span>Reporting</span>
        </DropdownItem>
        <DropdownItem $hoverColor="#7BFFFF" to="https://intelligence.runway.com/">
          <AmbientIcon />
          <span>Ambient Intelligence</span>
        </DropdownItem>
      </DropdownMenu>
    </DropdownWrapper>
  )
}

export function ResourcesDropdownLink({
  children,
  className,
}: {
  children: ReactNode
  className: string
}) {
  return (
    <ResourcesDropdownWrapper className={className}>
      <Arrow />
      {children}
      <DropdownMenu>
        <DropdownItem to="/about">
          <span>About</span>
        </DropdownItem>
        <DropdownItem to="/careers">
          <span>Careers</span>
        </DropdownItem>
        <DropdownItem to="https://blog.runway.com/">
          <span>Blog</span>
        </DropdownItem>
        <DropdownItem to="https://help.runway.com/">
          <span>Help Center</span>
        </DropdownItem>
      </DropdownMenu>
    </ResourcesDropdownWrapper>
  )
}

const Arrow = styled(ArrowSVG)`
  position: relative;
  height: auto;
  transition:
    transform 0.2s,
    background 0.2s;

  path {
    fill: ${colors.primaryWhite};
    transition: fill 0.2s;
  }

  ${fresponsive(css`
    width: 16px;
    top: -1.5px;
    padding: 3px;
    border-radius: 50%;
  `)}
`

const Wrapper = styled(UniversalLink)`
  width: fit-content;
  display: flex;
  align-items: center;
  ${textStyles.title4}
  color: ${colors.primaryWhite};
  height: 100%;

  ${media.hover} {
    &:hover {
      ${Arrow} {
        transform: rotate(-45deg);
        background: ${colors.primaryYellow};

        path {
          fill: ${colors.primaryBlack};
        }
      }
    }
  }

  ${fresponsive(css`
    gap: 7.5px;
  `)}
`

const DropdownItem = styled(UniversalLink)<{ $hoverColor?: string }>`
  display: flex;
  align-self: stretch;
  border-bottom: 1px solid ${colors.dark600};
  ${fresponsive(css`
    width: 192px;
    padding: 16px 0;
    align-items: center;
    gap: 12px;

    svg {
      height: 16px;
      width: 16px;
      color: ${colors.primaryWhite};
      transition: color 0.2s;
      margin-bottom: 3px;
    }
  `)}
  ${media.hover} {
    &:hover {
      span {
        color: ${colors.primaryWhite} !important;
      }

      svg {
        color: ${({ $hoverColor }) => $hoverColor} !important;
      }
    }
  }

  span {
    color: ${colors.primaryWhite};
    transition: color 0.2s;
    ${textStyles.title4};
  }
`

const DropdownMenu = styled.div`
  display: flex;
  position: absolute;
  ${fresponsive(css`
    top: 40px;
    border-radius: 20px;
    background: rgb(39 48 54 / 93%);
    box-shadow: 0 0 48px 7px rgb(0 0 0 / 13%);
    backdrop-filter: blur(8px);
    padding: 0 20px;
  `)}
  left: 0;
  flex-direction: column;
  align-items: flex-start;
  border: 1.25px solid;
  border-color: transparent;
  border-width: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  transition:
    width 0.2s,
    height 0.2s,
    border 0.2s;

  &:hover {
    ${DropdownItem} {
      svg,
      span {
        color: ${colors.dark300};
      }
    }
  }
`

const DropdownWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  ${textStyles.title4}
  color: ${colors.primaryWhite};
  height: 100%;
  position: relative;

  ${media.hover} {
    &:hover {
      ${Arrow} {
        transform: rotate(45deg);
        background: ${colors.primaryYellow};

        path {
          fill: ${colors.primaryBlack};
        }
      }
      ${DropdownMenu} {
        ${fresponsive(css`
          width: calc(192px + 40px);
          height: 208px;
          border-color: ${colors.grayBorder};
          border-width: 1.25px;
        `)}
      }
    }
  }

  ${fresponsive(css`
    gap: 7.5px;
  `)}
`

const ResourcesDropdownWrapper = styled(DropdownWrapper)`
  ${media.hover} {
    &:hover {
      ${DropdownMenu} {
        ${fresponsive(css`
          height: 188px;
        `)}
      }
    }
  }
`
