import { ScreenProvider } from 'library/ScreenContext'

import { EarlyAccessProvider } from './EarlyAccessContext'

interface ProvidersProps {
  children: React.ReactNode
}

export default function Providers({ children }: ProvidersProps) {
  return (
    <ScreenProvider>
      <EarlyAccessProvider>{children}</EarlyAccessProvider>
    </ScreenProvider>
  )
}
