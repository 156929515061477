import defaultConfig from 'library/defaultConfig'
import { isIOS } from 'library/deviceDetection'

/**
 * config for the reform util library
 * see src/library/defaultConfig.ts for the default config
 */

/**
 * The transition names that can be used in the page transition
 */
export type TransitionNames = 'blue' | 'asdfaff' | 'slide'

const config = {
  ...defaultConfig,
  /**
   * if true, the fresponsive util will scale on fullWidth breakpoints
   */
  scaleFully: true,
  /**
   * get the amount of time needed to load the page
   * @param startTime the number of MS the page spent loading on the network so far
   */
  getTimeNeeded: (startTime: number) => (isIOS() ? startTime + 3000 : startTime),
  /**
   * the transition that will be used by default
   */
  defaultTransition: 'slide' satisfies TransitionNames as TransitionNames,
}

export default config
