const colors = {
  pureBlack: '#000000',
  pureWhite: '#FFFFFF',
  // Darks
  primaryBlack: '#192227',
  dark700: '#2C353A',
  dark600: '#4A5357',
  dark500: '#5C656A',
  dark400: '#727B80',
  dark300: '#8A9499',
  dark200: '#9CA5A9',

  // Lights

  primaryWhite: '#FDFCFC',
  light200: '#F0EDED',
  light300: '#E0DEDE',
  light400: '#D1CECE',
  light500: '#B4AFAF',
  light600: '#969292',
  light700: '#807A7A',

  // Primary
  primaryRed: '#F06246',
  primaryYellow: '#F9A600',
  primaryBlue: '#2C8AC0',

  // Secondary
  primaryPurple: '#B08CE1',
  primaryGreen: '#79C44B',
  red400: '#FC7A5F',
  red600: '#B93C23',
  yellow400: '#FFC655',
  yellow600: '#DD8411',
  blue400: '#3CB8FF',
  blue600: '#1B648E',
  purple400: '#D4BCF4',
  purple600: '#6F45A8',
  green400: '#ADE988',
  green600: '#427D1D',
  secondaryPurple: '#B08CE1',

  // Product
  productWhite: '#FFFFFF',
  gray100: '#F7F8FA',
  gray200: '#EFF1F5',
  gray300: '#DCE1EA',
  gray400: '#B9C0CD',
  gray500: '#949DAD',
  gray600: '#464A53',
  grayBorder: '#333D43',
  grayBg: '#273036',
  blueLight: '#F5FBFF',
  blueMedium: '#F5FBFF',
  blueSelect: '#18A0FB',
  blueDark: '#7092C2',

  // Launch
  launchBg: '#141E14',
  launchTableBorder: '#222C23',
  launchMarqueeText: '#405741',
  launchHightlightGreen: '#00B700',
  launchLightGreen: '#9CCB9C',
  launchSubmittedGreen: '#97F497',
  launchErrorInputText: '#E43C1A',
  launchErrorBg: '#FF2E00',
  launchErrBannerText: '#191E14',
  launchShareButton: '#3F4C3F',
} as const

export default colors
