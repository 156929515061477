export const routes = {
  terms: '/terms',
  about: '/about/',
  home: '/',
  privacy: '/privacy',
  security: '/security',
  careers: '/careers/',
  customers: '/customer-stories/',
  collaborativePlanning: '/product/collaborative-planning',
  intuitiveModeling: '/product/intuitive-modeling',
  reporting: '/product/reporting',
} as const

export const socials = {
  linkedin: 'https://www.linkedin.com/company/runway/',
  twitter: 'https://twitter.com/runwayco',
  youtube: 'https://www.youtube.com/@runwayfinancial',
  instagram: 'https://www.instagram.com/runwayfinancial/',
} as const

export const companies = {
  angelList: 'https://www.angellist.com/',
  superHuman: 'https://superhuman.com/',
  stabilityAI: 'https://stability.ai/',
  lob: 'https://www.lob.com/',
  grain: 'https://grain.com/',
  sandboxVR: 'https://sandboxvr.com/',
  adquick: 'https://www.adquick.com/',
  cocoDelivery: 'https://cocodelivery.com/',
  pluralPolicy: 'https://www.pluralpolicy.com/',
  adobe: 'https://www.adobe.com/',
  stripe: 'https://www.stripe.com/',
  playgroundAI: 'https://playgroundai.com/',
  carta: 'https://carta.com/',
  rippling: 'https://www.rippling.com/',
  color: 'https://www.color.com/',
  loom: 'https://www.loom.com/',
  front: 'https://front.com/',
  mercury: 'https://mercury.com/',
  twitch: 'https://www.twitch.tv/',
  notion: 'https://www.notion.so/',
  tinder: 'https://tinder.com/',
  figma: 'https://www.figma.com/',
  zillow: 'https://www.zillow.com/',
  initialized: 'https://initialized.com/',
  horowitz: 'https://a16z.com/',
  shrug: 'https://www.shrug.vc/',
  molecule: 'https://www.molecule.io/',
  timesclub: 'https://home.timesclub.co/',
  tribePayments: 'https://www.tribepayments.com/',
} as const

const links = {
  ...routes,
  ...socials,
  ...companies,
  login: 'https://app.runway.com/login',
  contact: 'mailto:success@runway.com',
  helpCenter: 'https://help.runway.com/',
  blog: 'https://blog.runway.com/',
  ambientIntelligence: 'https://intelligence.runway.com/',
} as const

export default links
