import * as Collapsible from '@radix-ui/react-collapsible'
import useCanHover from 'library/canHover'
import { fmobile, fresponsive, ftablet } from 'library/fullyResponsive'
import { useState } from 'react'
import { useThrottle } from 'react-use'
import styled, { css } from 'styled-components'
import colors from 'styles/colors'
import media from 'styles/media'

export default function OpenMenuButton({ open }: { open: boolean }) {
  const [rawHover, setIsHovering] = useState(false)
  const forceHover = !useCanHover()

  // if the device can't hover, force the hover state to mitigate strange animations
  const isHovering = useThrottle(forceHover ? true : rawHover, 500)

  return (
    <Trigger
      aria-label="Open Menu"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      $slidDown={open}
    >
      <Line isOpen={open} isHovering={isHovering} />
      <Line isOpen={open} isHovering={isHovering} />
      <Line isOpen={open} isHovering={isHovering} />
      <Line isOpen={open} isHovering={isHovering} />
      <Line isOpen={open} isHovering={isHovering} />
      <Line isOpen={open} isHovering={isHovering} />
    </Trigger>
  )
}

const Trigger = styled(Collapsible.Trigger)<{
  $slidDown: boolean
}>`
  cursor: pointer;
  ${({ $slidDown }) =>
    fresponsive(css`
      height: 35px;
      width: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 0;
      transition: transform 1s;
      transform: translateY(${$slidDown ? '9px' : '-1px'});
      position: sticky;
      top: 0;
      z-index: 2;
    `)}

  ${ftablet(css`
    height: 48px;
    width: 96px;
  `)}


  ${({ $slidDown }) =>
    fmobile(css`
      height: 48px;
      width: 96px;
      margin: 0 0 0 auto;
      transform: translate(${$slidDown ? '25px, 8px' : '0, -1px'});
    `)}

  :focus-visible {
    outline: none;
    border: 4px solid ${colors.primaryBlue}cc;
    border-radius: 99vw;
  }
`

const staggerAnimations = css`
  /* set up a stagger */
  :nth-child(1),
  :nth-child(2) {
    transition-delay: 0.15s;
  }

  :nth-child(3),
  :nth-child(4) {
    transition-delay: 0.1s;
  }

  :nth-child(5),
  :nth-child(6) {
    transition-delay: 0.05s;
  }

  /* and reverse that stagger when hovered */
  ${media.hover} {
    ${Trigger}:hover & {
      :nth-child(1),
      :nth-child(2) {
        transition-delay: 0.05s;
      }

      :nth-child(3),
      :nth-child(4) {
        transition-delay: 0.1s;
      }

      :nth-child(5),
      :nth-child(6) {
        transition-delay: 0.15s;
      }
    }
  }
`

const Line = styled.div<{
  isOpen: boolean
  isHovering: boolean
}>`
  /* Always applied properties */
  ${fresponsive(css`
    height: 2px;
    background: ${colors.primaryWhite};
    margin-top: 3px;
    transition: all 0.5s;

    :nth-child(2),
    :nth-child(4),
    :nth-child(6) {
      margin-top: -2px;
    }
  `)}

  /* Resting States */
  ${({ isOpen }) =>
    isOpen
      ? fresponsive(css`
          /* Resting State While Open */
          width: 40px;
          transform: translateY(-50px);

          :nth-child(2) {
            transform: translate(-10px, 5px) rotate(-45deg);
          }

          :nth-child(4) {
            transform: translate(-10px, 0) rotate(-135deg);
          }

          :nth-child(6) {
            transform: translate(-10px, -5px) rotate(-135deg);
          }
        `)
      : fresponsive(css`
          /* Resting State While Closed */
          width: 60px;
          ${staggerAnimations};
          transform: translateY(0);

          :nth-child(2),
          :nth-child(4),
          :nth-child(6) {
            transform: translateY(40px);
          }
        `)}


  /* Hover States */

  /* For some reason, styled struggles a big when we use :hover, so I'm doing it in JS instead */
  ${({ isHovering, isOpen }) =>
    isHovering &&
    (isOpen
      ? fresponsive(css`
          /* Hover State While Open */
          transform: translateY(-50px);
          width: 40px;

          :nth-child(2) {
            transform: translate(-10px, 5px) rotate(45deg);
          }

          :nth-child(4) {
            transform: translate(-10px, 0) rotate(-45deg);
          }

          :nth-child(6) {
            transform: translate(-10px, -5px) rotate(-45deg);
          }
        `)
      : fresponsive(css`
          /* Hover State While Closed */
          transform: translateY(-40px);
          width: 60px;

          :nth-child(2),
          :nth-child(4),
          :nth-child(6) {
            transform: translateY(0);
          }
        `))}
`
