import * as Collapsible from '@radix-ui/react-collapsible'
import * as Portal from '@radix-ui/react-portal'
import { EarlyAccessContext } from 'components/Providers/EarlyAccessContext'
import UniversalLink from 'components/UniversalLink'
import { gsap } from 'gsap'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { ReactComponent as ArrowSVG } from 'images/global/buttonArrow.svg'
import { ReactComponent as AmbientSVG } from 'images/product/icons/ambient-intelligence.svg'
import { ReactComponent as CollabSVG } from 'images/product/icons/collaborative-planning.svg'
import { ReactComponent as ModelingSVG } from 'images/product/icons/intuitive-modeling.svg'
import { ReactComponent as ReportSVG } from 'images/product/icons/reports.svg'
import ClientOnly from 'library/ClientOnly'
import { fmobile, fresponsive, ftablet } from 'library/fullyResponsive'
import loader from 'library/Loader'
import useAnimation from 'library/useAnimation'
import useMedia from 'library/useMedia'
import { useResponsivePixels } from 'library/viewportUtils'
import { useContext, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import colors from 'styles/colors'
import media from 'styles/media'
import text from 'styles/text'
import links from 'utils/links'

import OpenMenuButton from './OpenMenuButton'

export default function DropdownNavigation({
  earlyAccessButton = null,
  onOpenChange,
}: {
  earlyAccessButton?: React.ReactNode
  onOpenChange?: (open: boolean) => void
}) {
  const [open, setOpen] = useState(false)
  const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null)
  const [outer, setOuter] = useState<HTMLDivElement | null>(null)
  const overlay = useRef<HTMLDivElement | null>(null)
  const shouldPauseScroll = useMedia(false, false, true, true)

  const { open: earlyAccessOpen } = useContext(EarlyAccessContext)

  const responsive = useMedia(false, false, true, true)
  const buttonSlideDown = useResponsivePixels(10)
  const openWidth = useResponsivePixels(272)

  const allWidths = useMedia(`${openWidth}px`, `${openWidth}px`, 'auto', 'auto')

  useEffect(() => {
    if (onOpenChange) onOpenChange(open)
  }, [onOpenChange, open])

  /**
   * close the menu when the user scrolls
   */
  useEffect(() => {
    if (!shouldPauseScroll) {
      const onScroll = () => {
        setOpen(false)
      }
      window.addEventListener('scroll', onScroll)
      return () => {
        window.removeEventListener('scroll', onScroll)
      }
    }
  }, [shouldPauseScroll])

  /**
   * close the menu when the page changes
   */
  useEffect(() => {
    const onNavigate = () => setOpen(false)
    loader.addEventListener('transitionStart', onNavigate)
    return () => loader.removeEventListener('transitionStart', onNavigate)
  }, [])

  /**
   * menu open and close animation
   */
  useAnimation(
    () => {
      const duration = 0.6
      const values = {
        duration,
        ease: 'power2.inOut',
      }
      if (outer) outer.style.removeProperty('transform')
      if (open) {
        gsap.to(overlay.current, {
          autoAlpha: 1,
        })
        gsap.set(wrapper, {
          autoAlpha: 1,
        })
        gsap.to(wrapper, {
          height: 'auto',
          width: allWidths,
          ...values,
        })
        gsap.to('#open-access-desktop', {
          y: buttonSlideDown,
          ...values,
        })
        if (earlyAccessButton) {
          gsap.set(outer, {
            overflowY: 'auto',
            delay: duration,
          })
        }
      } else {
        gsap.to(overlay.current, {
          autoAlpha: 0,
        })
        gsap.set(wrapper, {
          autoAlpha: 0,
          delay: duration,
        })
        gsap.to(wrapper, {
          height: 0,
          width: 0,
          ...values,
        })
        gsap.to('#open-access-desktop', {
          y: 0,
          ...values,
        })
        if (earlyAccessButton) {
          gsap.set(outer, {
            clearProps: 'overflow',
          })
        }
      }
    },
    [buttonSlideDown, earlyAccessButton, open, outer, wrapper, allWidths],
    {
      kill: true,
    },
  )

  /**
   * slide on scroll animation
   */
  useAnimation(
    () => {
      if (outer && !responsive) {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: outer,
            start: 0,
            end: '+=300',
            scrub: true,
          },
        })

        tl.fromTo(
          outer,
          {
            display: 'none',
            opacity: 0,
          },
          {
            display: 'block',
            opacity: 1,
            duration: 0.5,
          },
          0.5,
        )
        tl.fromTo(
          outer,
          {
            xPercent: 115,
          },
          {
            xPercent: 0,
            duration: 1,
            ease: 'power4.inOut',
          },
          0,
        )

        const onPageTransition = () => {
          gsap.to(tl, { progress: 0 })
        }

        loader.addEventListener('transitionStart', onPageTransition)
        return () => {
          loader.removeEventListener('transitionStart', onPageTransition)
        }
      }
    },
    [outer, responsive],
    {
      recreateOnResize: true,
    },
  )

  /**
   * close the menu when the user clicks early access
   */
  useEffect(() => {
    if (earlyAccessOpen) setOpen(false)

    gsap.to(outer, {
      yPercent: earlyAccessOpen ? -250 : 0,
      delay: earlyAccessOpen ? 0 : 1,
      ease: 'power3.inOut',
    })
  }, [earlyAccessOpen, outer])

  return (
    <Wrapper
      onOpenChange={(newOpen) => {
        if (shouldPauseScroll) ScrollSmoother.get()?.paused(newOpen)
        else ScrollSmoother.get()?.paused(false)
        setOpen(newOpen)
      }}
      ref={setOuter}
      open={open}
    >
      <ClientOnly>
        <Portal.Root>
          <Overlay
            ref={overlay}
            role="presentation"
            onClick={() => {
              setOpen(false)
            }}
          />
        </Portal.Root>
      </ClientOnly>
      <OpenMenuButton open={open} />
      <Content forceMount ref={setWrapper}>
        <Spacer />
        <>
          {earlyAccessButton}
          <Section>
            <SectionLabel>Product</SectionLabel>
            <SectionLink to={links.collaborativePlanning} hovercolor={colors.blue400}>
              <IconCollab /> <span>Collaborative Planning</span>
            </SectionLink>
            <SectionLink to={links.intuitiveModeling} hovercolor={colors.green400}>
              <IconModeling /> Intuitive Modeling
            </SectionLink>
            <SectionLink to={links.reporting} hovercolor={colors.purple400}>
              <IconReport /> Reporting
            </SectionLink>
            <SectionLink to={links.ambientIntelligence} hovercolor="#7BFFFF">
              <IconAmbient /> Ambient Intelligence
            </SectionLink>
          </Section>
          <Section>
            <SectionLabel>Resources</SectionLabel>
            <SectionLink to={links.about}>About</SectionLink>
            <SectionLink to={links.careers}>Careers</SectionLink>
            <SectionLink to={links.helpCenter}>Help Center</SectionLink>
            <SectionLink to={links.blog}>Blog</SectionLink>
          </Section>
          <DropdownLink to={links.customers} onClick={() => setOpen(false)}>
            <span>Customers </span> <Arrow />
          </DropdownLink>
          <DropdownLink to={links.login} onClick={() => setOpen(false)}>
            Log In <Arrow />
          </DropdownLink>
        </>
        <Socials>
          <Social to={links.linkedin}>LinkedIn</Social>
          <Social to={links.twitter}>Twitter</Social>
        </Socials>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled(Collapsible.Root)<{
  open: boolean
}>`
  ${fresponsive(css`
    border-radius: 26px;
    isolation: isolate;
    background: rgb(25 34 39 / 88%);
    backdrop-filter: blur(7px);
    position: absolute;
    left: calc(100% - 268px);
    min-height: 33px;
    min-width: 90px;
    border: 1px solid ${colors.primaryWhite};

    /* clip prevents scrolling edge cases, but isn't fully supported in ios 15 */
    overflow: hidden;
    overflow: clip;
  `)};

  ${({ open }) =>
    ftablet(css`
      min-width: 96px;
      min-height: 48px;
      border-radius: ${open ? '20px' : '30px'};
      transition: border-radius 0.3s ease-in-out;
      left: calc(100% - 339px);
    `)}
  ${({ open }) =>
    fmobile(css`
      min-width: 96px;
      min-height: 48px;
      transform: translateY(-29px);
      border-radius: ${open ? '20px' : '30px'};
      transition: border-radius 0.3s ease-in-out;
      left: unset;
      right: 5px;
      max-height: 90vh;
      max-height: 98svh;
    `)}
`

const Overlay = styled.div`
  visibility: hidden;
  position: fixed;
  z-index: 1;
  inset: 0;
  background: rgba(0 0 0 / 60%);
  display: none;
  height: 100vh;

  ${ftablet(css`
    display: block;
  `)}
  ${fmobile(css`
    display: block;
  `)}
`

const Content = styled(Collapsible.Content)`
  position: relative;
  z-index: 1;
  opacity: 0;
  height: 0;
  width: 0;
`

const Spacer = styled.div`
  ${fresponsive(css`
    height: 20px;
  `)}
  ${ftablet(css`
    height: 20px;
  `)}
  ${fmobile(css`
    height: 18px;
  `)}
`

const Section = styled.div`
  border-top: 1px solid ${colors.dark600};
  width: 100%;

  ${fresponsive(css`
    padding-top: 20px;
    padding-bottom: 8px;
  `)}

  ${ftablet(css`
    padding-bottom: 12px;
  `)}

  ${fmobile(css`
    padding-bottom: 12px;
  `)}
`

const SectionLabel = styled.div`
  ${text.title3}
  color: ${colors.dark300};

  ${fresponsive(css`
    padding: 0 20px;
    margin-bottom: 8px;
  `)}
`

const BaseIcon = css`
  height: auto;

  path {
    fill: ${colors.primaryWhite};
    transition: fill 0.3s ease-in-out;
  }

  ${fresponsive(css`
    width: 16px;
  `)}

  ${fmobile(css`
    width: 14px;
  `)}
`

const IconCollab = styled(CollabSVG)`
  ${BaseIcon};
`

const SectionLink = styled(UniversalLink)<{
  hovercolor?: string
}>`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  ${text.title3};
  gap: 8px;
  transition: color 0.3s ease-in-out;

  ${IconCollab} {
    margin-bottom: 3px;
  }

  ${fresponsive(css`
    height: 36px;
    width: 276px;
    padding: 0 20px;
  `)};

  ${ftablet(css`
    height: 42px;
    width: 342px;

    ${IconCollab} {
      path {
        fill: ${colors.primaryWhite};
      }
    }
  `)}

  ${fmobile(css`
    height: 44px;
    width: 365px;

    ${IconCollab} {
      path {
        fill: ${colors.primaryWhite};
      }
    }
  `)}

  ${media.hover} {
    :hover {
      svg {
        path {
          fill: ${({ hovercolor }) => hovercolor};
        }
      }
    }
  }
`

const IconModeling = styled(ModelingSVG)`
  ${BaseIcon};
`

const IconReport = styled(ReportSVG)`
  ${BaseIcon};
`
const IconAmbient = styled(AmbientSVG)`
  ${BaseIcon};
`

const Arrow = styled(ArrowSVG)`
  height: auto;
  transition: transform 0.3s ease-in-out;

  path {
    fill: ${colors.primaryBlack};
  }

  ${fresponsive(css`
    width: 10px;
  `)}

  ${ftablet(css`
    width: 12px;
  `)}
`

const DropdownLink = styled(UniversalLink)`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${colors.dark600};
  transition: all 0.3s ease-in-out;
  ${text.title3};

  ${Arrow} {
    path {
      fill: ${colors.primaryWhite};
    }

    ${fresponsive(css`
      width: 15px;
    `)}
  }

  ${fresponsive(css`
    height: 55px;
    width: 276px;
    padding: 0 20px;
  `)};

  ${ftablet(css`
    ${text.title3}
    height: 52px;
    width: 342px;

    ${Arrow} {
      path {
        fill: ${colors.primaryWhite};
      }
    }
  `)}

  ${fmobile(css`
    ${text.title3}
    height: 56px;
    width: 365px;

    ${Arrow} {
      path {
        fill: ${colors.primaryWhite};
      }
    }
  `)}

  ${media.hover} {
    :hover {
      ${Arrow} {
        transform: rotate(-45deg);

        path {
          fill: ${colors.primaryYellow};
        }
      }

      color: ${colors.primaryYellow};
    }
  }
`

const Socials = styled.div`
  display: none;
  align-items: center;
  width: 100%;
  border-top: 1px solid ${colors.dark600};

  ${fmobile(css`
    height: 60px;
    display: flex;
  `)}
`

const Social = styled(UniversalLink)`
  ${text.bodyS}
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  ${fmobile(css`
    ${text.title3};
  `)}

  &:last-of-type {
    border-left: 1px solid ${colors.dark600};
  }
`
