import fullyResponsive from 'library/fullyResponsive'
import { css } from 'styled-components'

const textStyles = {
  h1: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-size: 185px;
    line-height: 90%;
    letter-spacing: -0.04em;
  `),
  h2: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 145px;
    line-height: 90%;
    letter-spacing: -0.04em;
  `),
  h3: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: min(125px, 12.5vh);
    line-height: 90%;
    letter-spacing: -0.04em;
  `),
  h4: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 105px;
    line-height: 90%;
    letter-spacing: -0.04em;
  `),
  h5: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 85px;
    line-height: 90%;
    letter-spacing: -0.04em;
  `),
  h6: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 90%;
    letter-spacing: -0.04em;
  `),
  h7: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 90%;
    letter-spacing: -0.04em;
  `),
  h7Alt: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 42px */
    letter-spacing: -1.68px;
  `),
  h8: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 90%;
    letter-spacing: -0.04em;
  `),
  title1: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 90%;
    letter-spacing: -0.03em;
  `),
  title2: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 100%;
    letter-spacing: -0.03em;
  `),
  title3: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -0.03em;
  `),
  title4: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: -0.02em;
  `),
  title5: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
  `),
  bodyXL: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 125%;
    letter-spacing: -0.02em;
  `),
  bodyL: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 125%;
    letter-spacing: -0.02em;
  `),
  bodyR: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 125%;
    letter-spacing: -0.02em;
  `),
  bodyS: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 125%;
    letter-spacing: -0.01em;
  `),
  bodyXS: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 128%;
  `),
  bodyXXS: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 128%;
  `),
  kickerXL: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-transform: uppercase;
  `),
  kickerL: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    letter-spacing: -0.01em;
    text-transform: uppercase;
  `),
  kickerR: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    text-transform: uppercase;
  `),
  kickerS: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    text-transform: uppercase;
  `),
  productH1: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 98px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -3.92px;
  `),
  productTabletH1: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 88px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -3.52px;
  `),
  productH2: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 68px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -2.88px;
  `),
  productTabletH2: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -1.92px;
  `),
  productH3: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 55px;
    line-height: 100%;
    letter-spacing: -2.2px;
  `),
  productH4: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -1.76px;
  `),
  productH5: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -1.6px;
  `),
  productH6: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -1.44px;
  `),
  productH7: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -1.28px;
  `),
  productH8: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.78px;
  `),
  productH9: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.72px;
  `),
  productHeader: fullyResponsive(css`
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
  `),
  productLead1: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.44px;
  `),
  productLead2: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.4px;
  `),
  productLead3: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.36px;
  `),
  productLead4: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.32px;
  `),
  productLead5: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.3px;
  `),
  productQuote1: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -1.44px;
  `),
  productQuote1Tablet: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -1.2px;
  `),
  productQuote2: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -0.84px;
  `),
  productByline1: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    line-height: 100%;
    letter-spacing: -0.66px;
  `),
  productByline2: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-style: normal;
    line-height: 100%;
    letter-spacing: -0.45px;
  `),
  productBody1: fullyResponsive(css`
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
  `),
  productStat1: fullyResponsive(css`
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
  `),
  productPage: fullyResponsive(css`
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  `),
  productPageBold: fullyResponsive(css`
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
  `),
  productButton1: fullyResponsive(css`
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 120%;
  `),
  descriptionL: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 63px */
    letter-spacing: -1.8px;
  `),
  descriptionM: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 42px */
    letter-spacing: -1.2px;
  `),
  descriptionS: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 35px */
    letter-spacing: -1px;
  `),
  navPill: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.26px;
  `),
  rtBody2XL: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.48px;
  `),
  rtBodyXL: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.44px;
  `),
  rtBodyM: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.36px;
  `),
  rtBodySmall: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.3px;
  `),
  rtBodyXS: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.39px;
  `),
  rtQuote: fullyResponsive(css`
    font-family: 'PP Mori', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -1.08px;
  `),
}

// eslint rules below because we still need webkit prefix for chrome
export const strokeText = css`
  /* stylelint-disable-next-line property-no-vendor-prefix  */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
  -webkit-text-stroke-width: 0.07vw;
`

export const strokeTextTransparent = css`
  /* stylelint-disable-next-line property-no-vendor-prefix  */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
  -webkit-text-stroke-width: 0.07vw;
`

export const transparentText = css`
  /* stylelint-disable-next-line property-no-vendor-prefix  */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
`

export default textStyles
