exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-customer-stories-tsx": () => import("./../../../src/pages/customer-stories.tsx" /* webpackChunkName: "component---src-pages-customer-stories-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-launch-tsx": () => import("./../../../src/pages/launch.tsx" /* webpackChunkName: "component---src-pages-launch-tsx" */),
  "component---src-pages-legal-msa-tsx": () => import("./../../../src/pages/legal/msa.tsx" /* webpackChunkName: "component---src-pages-legal-msa-tsx" */),
  "component---src-pages-legal-sla-tsx": () => import("./../../../src/pages/legal/sla.tsx" /* webpackChunkName: "component---src-pages-legal-sla-tsx" */),
  "component---src-pages-partners-jpmc-tsx": () => import("./../../../src/pages/partners/jpmc.tsx" /* webpackChunkName: "component---src-pages-partners-jpmc-tsx" */),
  "component---src-pages-partners-puzzle-tsx": () => import("./../../../src/pages/partners/puzzle.tsx" /* webpackChunkName: "component---src-pages-partners-puzzle-tsx" */),
  "component---src-pages-partners-twist-tsx": () => import("./../../../src/pages/partners/twist.tsx" /* webpackChunkName: "component---src-pages-partners-twist-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-product-collaborative-planning-tsx": () => import("./../../../src/pages/product/collaborative-planning.tsx" /* webpackChunkName: "component---src-pages-product-collaborative-planning-tsx" */),
  "component---src-pages-product-intuitive-modeling-tsx": () => import("./../../../src/pages/product/intuitive-modeling.tsx" /* webpackChunkName: "component---src-pages-product-intuitive-modeling-tsx" */),
  "component---src-pages-product-reporting-tsx": () => import("./../../../src/pages/product/reporting.tsx" /* webpackChunkName: "component---src-pages-product-reporting-tsx" */),
  "component---src-pages-request-demo-tsx": () => import("./../../../src/pages/request-demo.tsx" /* webpackChunkName: "component---src-pages-request-demo-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-customer-story-tsx": () => import("./../../../src/templates/customer-story.tsx" /* webpackChunkName: "component---src-templates-customer-story-tsx" */)
}

