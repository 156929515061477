import { gsap } from 'gsap'
import { lazy, Suspense, useEffect, useState } from 'react'
import styled from 'styled-components'

const BoardingPass = lazy(() => import('components/BoardingPass'))

export default function Cta() {
  const [wrapperEl, setWrapperEl] = useState<HTMLElement | null>(null)
  const [client, setClient] = useState<{
    x: number
    y: number
  }>()

  useEffect(() => {
    const mouseMove = (e: PointerEvent) => {
      if (e.pointerType === 'touch')
        return setClient({
          x: 0,
          y: 0,
        })
      setClient({ x: e.clientX, y: e.clientY })
    }
    wrapperEl?.addEventListener('pointermove', mouseMove)
    return () => {
      wrapperEl?.removeEventListener('pointermove', mouseMove)
    }
  }, [wrapperEl])

  return (
    <Suspense>
      <Wrapper ref={(ref) => setWrapperEl(ref)}>
        <BoardingPass client={client} />
      </Wrapper>
    </Suspense>
  )
}

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  perspective: 1000px;
`

export const boardingPassIsOnScreen = () => {
  const wrapper = document.querySelector(Wrapper.toString())
  if (!wrapper) return false
  const rect = wrapper.getBoundingClientRect()
  return rect.top < window.innerHeight * 2 || rect.bottom < 0
}

export const scrollToBoardingPass = () => {
  gsap.to(window, {
    scrollTo: {
      y: Wrapper.toString(),
      offsetY: 100,
    },
  })
}
