import { EarlyAccessContext } from 'components/Providers/EarlyAccessContext'
import UniversalLink from 'components/UniversalLink'
import { useLayoutProps } from 'contexts/LayoutContext'
import { gsap } from 'gsap'
import { ReactComponent as ArrowSVG } from 'images/global/buttonArrow.svg'
import { ReactComponent as LogoSvg } from 'images/global/RunwayLogo.svg'
import { ReactComponent as JPMCLogoSVG } from 'images/global/RunwayWithJPMC.svg'
import { ReactComponent as PuzzleLogoSVG } from 'images/global/RunwayWithPuzzle.svg'
import { ReactComponent as TwistLogoSVG } from 'images/global/RunwayWithTwist.svg'
import ClientOnly from 'library/ClientOnly'
import { fmobile, fresponsive, ftablet } from 'library/fullyResponsive'
import loader from 'library/Loader'
import { loadPage } from 'library/Loader/TransitionUtils'
import useAnimation from 'library/useAnimation'
import useMedia from 'library/useMedia'
import { useContext, useRef, useState } from 'react'
import { boardingPassIsOnScreen, scrollToBoardingPass } from 'sections/home/07-Cta'
import styled, { css } from 'styled-components'
import colors from 'styles/colors'
import { eases } from 'styles/eases'
import media from 'styles/media'
import text from 'styles/text'
import { useJPMC } from 'utils/jpmc'
import links from 'utils/links'
import { usePuzzle } from 'utils/puzzle'
import { useTwist } from 'utils/twist'

import { useLocation } from '@reach/router'
import DropdownNavigation from './DropdownNavigation'
import Link, { ProductDropdownLink, ResourcesDropdownLink } from './Link'
import { useLogoPosition } from './useLogoPosition'

export default function Header() {
  const { setOpen: setEarlyAccessOpen, open: earlyAccessOpen } = useContext(EarlyAccessContext)

  const earlyAccessInMenu = useMedia(false, false, false, true)
  const desktop = useMedia(true, true, true, false)
  const wrapperRef = useRef<HTMLElement | null>(null)

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const logoPosition = useLogoPosition(dropdownOpen)
  const isJpmc = useJPMC()
  const isPuzzle = usePuzzle()
  const isTwist = useTwist()

  const { origin } = useLocation()

  useAnimation(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: 'header',
        start: 0,
        end: '+=300',
        scrub: true,
      },
    })
    tl.to('.nav-link', {
      yPercent: -250,
      stagger: {
        each: 0.15,
        from: 'end',
      },
      duration: 1.5,
      ease: 'power2.in',
    }).to('.nav-link', {
      autoAlpha: 0,
      stagger: {
        each: 0.1,
        from: 'end',
      },
      duration: 0.01,
    })

    const onPageTransition = () => {
      gsap.to(tl, { progress: 0 })
    }

    loader.addEventListener('transitionStart', onPageTransition)
    return () => loader.removeEventListener('transitionStart', onPageTransition)
  }, [])

  const getCtaText = () => {
    if (isTwist) {
      return 'Get 3 months free'
    } else if (isJpmc || isPuzzle) {
      return 'Get 2 months free'
    } else {
      return ctaCopy
    }
  }

  const { ctaCopy } = useLayoutProps()

  const earlyAccess = (
    <EarlyAccess
      type="button"
      $accessOpen={earlyAccessOpen}
      onClick={() => {
        if (boardingPassIsOnScreen()) scrollToBoardingPass()
        else setEarlyAccessOpen((p) => !p)
      }}
    >
      <EarlyAccessContent $accessOpen={earlyAccessOpen}>
        <div>{getCtaText()}</div>
        <Arrow />
      </EarlyAccessContent>
      <EarlyAccessContent $accessOpen={earlyAccessOpen}>
        <Arrow />
        Back To Site
      </EarlyAccessContent>
    </EarlyAccess>
  )

  const renderLogo = () => {
    if (isJpmc) {
      return <JPMCLogo />
    } else if (isPuzzle) {
      return <PuzzleLogo />
    } else if (isTwist) {
      return <TwistLogo />
    } else {
      return <Logo />
    }
  }

  const logo = earlyAccessOpen ? (
    <UniversalLink
      type="button"
      onClick={() => {
        setEarlyAccessOpen(false)
        loadPage(origin).catch(() => {
          window.open('/')
        })
      }}
      ariaLabel="Runway Home"
      openInNewTab={false}
      internalOverride
    >
      {renderLogo()}
    </UniversalLink>
  ) : (
    <UniversalLink to={origin} ariaLabel="Runway Home" openInNewTab={false} internalOverride>
      {renderLogo()}
    </UniversalLink>
  )

  return (
    <>
      {!desktop && (
        <LogoWrapper className="header-logo" $position={logoPosition}>
          {logo}
        </LogoWrapper>
      )}
      <Wrapper ref={wrapperRef}>
        <NavWrapper>
          {desktop && (
            <LogoWrapper className="header-logo" $position={logoPosition}>
              {logo}
            </LogoWrapper>
          )}

          <Right id="right-header">
            <Links>
              <ProductDropdownLink className="nav-link">Product</ProductDropdownLink>
              <Link className="nav-link" to={links.customers} key="customers">
                Customers
              </Link>
              <ResourcesDropdownLink className="nav-link">Resources</ResourcesDropdownLink>
              <Link className="nav-link" to={links.login} key="login">
                Log in
              </Link>
            </Links>
            <ClientOnly>
              <DropdownNavigation
                onOpenChange={setDropdownOpen}
                earlyAccessButton={earlyAccessInMenu ? earlyAccess : null}
              />
            </ClientOnly>
            {!earlyAccessInMenu && <div id="open-access-desktop">{earlyAccess}</div>}
          </Right>
        </NavWrapper>
      </Wrapper>
    </>
  )
}

export const EarlyAccess = styled(UniversalLink)<{
  $accessOpen: boolean
}>`
  background-color: ${colors.primaryYellow};
  color: ${colors.primaryBlack};
  ${text.title4}
  position: relative;
  z-index: 2;
  overflow: clip;
  border-radius: 99vw;
  border: 1px solid ${colors.primaryYellow};
  transition: width 0.25s;
  transition-delay: ${({ $accessOpen }) => ($accessOpen ? '0s' : '1s')};

  ${(props) =>
    fresponsive(css`
      height: 35px;
      width: ${props.$accessOpen ? 140 : 170}px;
    `)}

  ${ftablet(css`
    ${text.title3}
    height: 48px;
    padding: 0 20px;
    width: 232px;
  `)}

  ${fmobile(css`
    height: 60px;
    overflow: hidden;
    width: 100%;
    min-width: 265px;
    border-radius: unset;
    padding: 0 30px;
    ${text.title3}
  `)}
`

const Arrow = styled(ArrowSVG)`
  height: auto;
  transition: transform 0.3s ease-in-out;
  flex-shrink: 0;

  path {
    fill: ${colors.primaryBlack};
  }

  ${fresponsive(css`
    width: 10px;
  `)}

  ${ftablet(css`
    width: 12px;

    /* position: absolute; */
    right: 16px;
    transform: translateY(1px);
  `)}
`

const EarlyAccessContent = styled.span<{
  $accessOpen: boolean
}>`
  background-color: ${colors.primaryYellow};
  color: ${colors.primaryBlack};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  white-space: pre;
  transition: transform 0.3s ease-in-out;
  transition-delay: ${({ $accessOpen }) => ($accessOpen ? '0s' : '1s')};

  ${({ $accessOpen }) =>
    $accessOpen &&
    css`
      transform: translateY(-100%);
    `}

  div {
    position: relative;
    top: -1px;
  }

  :last-child {
    ${Arrow} {
      transform: rotate(-180deg);
    }

    ${fresponsive(css`
      top: 101%;
    `)}
  }

  ${media.hover} {
    ${EarlyAccess}:hover & {
      ${Arrow} {
        transform: rotate(-45deg);
      }

      :last-child {
        ${Arrow} {
          transform: rotate(-135deg);
        }
      }
    }
  }

  ${fresponsive(css`
    padding: 10px 15px;
    gap: 10px;

    > div {
      transform: translateY(1.5px);
    }
  `)}

  ${ftablet(css`
    padding: 0 20px;
  `)}

  ${fmobile(css`
    justify-content: space-between;
    padding: 0 20px;

    ${Arrow} {
      width: 15px;
    }
  `)}
`

const JPMCLogo = styled(JPMCLogoSVG)`
  position: relative;
  z-index: 3;
  height: auto;

  ${fresponsive(css`
    width: 296px;
    margin: -20px -188px -11px -8px;
  `)}

  ${ftablet(css`
    padding-top: 10px;
  `)}

  ${fmobile(css`
    padding-top: 10px;
    transform: scale(0.7);
    transform-origin: center left;
  `)}
`

const PuzzleLogo = styled(PuzzleLogoSVG)`
  position: relative;
  z-index: 3;
  height: auto;

  ${fresponsive(css`
    width: 296px;
    margin: -20px -188px -11px -8px;
  `)}

  ${ftablet(css`
    padding-top: 10px;
  `)}

  ${fmobile(css`
    padding-top: 10px;
    transform: scale(0.7);
    transform-origin: center left;
  `)}
`
const TwistLogo = styled(TwistLogoSVG)`
  position: relative;
  z-index: 3;
  height: auto;

  ${fresponsive(css`
    width: 296px;
    margin: -20px -188px -11px -8px;
  `)}

  ${ftablet(css`
    padding-top: 10px;
  `)}

  ${fmobile(css`
    padding-top: 10px;
    transform: scale(0.7);
    transform-origin: center left;
  `)}
`

const Logo = styled(LogoSvg)`
  position: relative;
  z-index: 3;
  height: auto;

  ${fresponsive(css`
    width: 100px;
  `)}

  ${ftablet(css`
    margin-top: 10px;
  `)}

  ${fmobile(css`
    margin-top: 10px;
  `)}
`

const LogoWrapper = styled.div<{
  $position: 'normal' | 'hidden' | 'dropdown'
}>`
  position: relative;
  z-index: 20;
  transition: translate 0.2s ${eases.quad.inOut};

  ${({ $position }) =>
    fresponsive(css`
      ${$position === 'dropdown' &&
      css`
        translate: 0 20%;
      `}
      ${$position === 'normal' &&
      css`
        translate: 0 0;
      `}
      ${$position === 'hidden' &&
      css`
        translate: 0 -325%;
      `}
    `)}

  ${fmobile(css`
    position: fixed;
    top: 12px;
    left: 23px;
  `)}
`

const Wrapper = styled.header`
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  color: ${colors.primaryWhite};

  ${fresponsive(css`
    padding: 0 15px;
  `)}

  ${ftablet(css`
    color: ${colors.primaryWhite};
    padding: 0 20px;
  `)}

  ${fmobile(css`
    color: ${colors.primaryWhite};
    padding: 0 4px;
    height: auto;
  `)}
`

const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${fresponsive(css`
    padding: 8px 0;
  `)}
  ${fmobile(css`
    min-height: 71.5px;
    height: auto;
  `)}
`

const Right = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;

  ${fresponsive(css`
    gap: 32px;
  `)}

  ${ftablet(css`
    gap: 30px;
  `)}
  ${fmobile(css`
    position: absolute;
    right: 0;
  `)}
`

const Links = styled.div`
  display: flex;
  align-items: center;

  ${fresponsive(css`
    gap: 45px;
  `)}

  ${ftablet(css`
    display: none;
  `)}

  ${fmobile(css`
    display: none;
  `)}
`
