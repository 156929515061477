import { graphql, useStaticQuery } from 'gatsby'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

export interface Banner {
  important: boolean
  desktopText: {
    desktopText: string
  }
  mobileText: {
    mobileText: string
  }
  url: string
}

export interface LayoutProps {
  background?: React.ReactNode
  hsFormId?: string
  defaultFormId?: number
  transparentFooterBg?: boolean
  ctaCopy: string
  banner?: Banner
}

interface LayoutContextState {
  layoutProps: LayoutProps
}

const defaultLayoutProps: LayoutProps = {
  ctaCopy: 'Book a demo',
}

interface LayoutContextType extends LayoutContextState {
  setLayoutProps: React.Dispatch<React.SetStateAction<LayoutProps>>
}

const defaultContextValue: LayoutContextType = {
  layoutProps: defaultLayoutProps,
  setLayoutProps: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
}

const LayoutContext = createContext<LayoutContextType>(defaultContextValue)

export const useLayoutContext = () => useContext(LayoutContext)

export const useLayoutProps = () => {
  const { layoutProps } = useLayoutContext()
  return layoutProps
}

export const useSetLayoutProps = (props: Partial<LayoutProps>) => {
  const { setLayoutProps } = useLayoutContext()
  // Spread props and use values as dependencies instead of the props object to avoid
  // re-renders when the props object reference changes
  useEffect(() => {
    setLayoutProps({
      background: props.background ?? defaultLayoutProps.background,
      hsFormId: props.hsFormId ?? defaultLayoutProps.hsFormId,
      defaultFormId: props.defaultFormId ?? defaultLayoutProps.defaultFormId,
      transparentFooterBg: props.transparentFooterBg ?? defaultLayoutProps.transparentFooterBg,
      ctaCopy: props.ctaCopy ?? defaultLayoutProps.ctaCopy,
    })
    return () => {
      setLayoutProps(defaultLayoutProps)
    }
  }, [
    props.background,
    props.hsFormId,
    props.defaultFormId,
    props.transparentFooterBg,
    props.ctaCopy,
    setLayoutProps,
  ])
}

interface SiteSettingsData {
  contentfulSiteSettings: {
    banner: {
      important: boolean
      desktopText: {
        desktopText: string
      }
      mobileText: {
        mobileText: string
      }
      url: string
    }
  }
}

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const siteSettings = useStaticQuery<SiteSettingsData>(graphql`
    query Settings {
      contentfulSiteSettings {
        banner {
          important
          desktopText {
            desktopText
          }
          url
          mobileText {
            mobileText
          }
        }
      }
    }
  `)

  const { banner } = siteSettings.contentfulSiteSettings

  const [layoutProps, setLayoutProps] = useState<LayoutProps>({
    ...defaultLayoutProps,
    banner,
  })

  const value = useMemo(
    () => ({
      layoutProps,
      setLayoutProps,
    }),
    [layoutProps],
  )

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}
