import gsap, { ScrollSmoother, ScrollTrigger } from 'gsap/all'
import loader from 'library/Loader'
import useAnimation from 'library/useAnimation'
import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useParamState } from 'utils/useParamState'

export interface EarlyAccessContextType {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>> | VoidFunction
}

export const EarlyAccessContext = createContext<EarlyAccessContextType>({
  open: false,
  setOpen: () => false,
})

export function EarlyAccessProvider({ children }: { children: ReactNode }) {
  const [openRaw, setOpenRaw] = useParamState('get-access')
  const [timeline, setTimeline] = useState<GSAPTimeline | null>(null)

  /**
   * track if the page is loading
   * if it is, we need to hide the early access button until the page is loaded
   */
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const onStarted = () => setIsLoading(true)
    const onFinished = () => setIsLoading(false)

    loader.addEventListener('anyStart', onStarted)
    loader.addEventListener('anyEnd', onFinished)
    return () => {
      loader.removeEventListener('anyStart', onStarted)
      loader.removeEventListener('anyEnd', onFinished)
    }
  })

  /**
   * custom state to convert string param to boolean
   */
  const open = isLoading ? false : openRaw === 'true'
  const setOpen = useCallback(
    (dispatcher: React.SetStateAction<boolean>) => {
      if (typeof dispatcher === 'function') {
        setOpenRaw(dispatcher(open) ? 'true' : null)
      } else {
        setOpenRaw(dispatcher ? 'true' : null)
      }
    },
    [open, setOpenRaw],
  )

  /**
   * default to open if the xero_signup param is present
   */
  useEffect(() => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    const xeroSignup = params.get('xero_signup')

    if (xeroSignup === 'true') setOpenRaw('true')
  }, [setOpenRaw])

  const values: EarlyAccessContextType = useMemo(
    () => ({
      open,
      setOpen,
    }),
    [open, setOpen],
  )

  useAnimation(() => {
    const tl = gsap.timeline({
      paused: true,
      onStart: () => {
        ScrollSmoother.get()?.paused(true)
      },
      onReverseComplete: () => {
        ScrollSmoother.get()?.paused(false)
        ScrollTrigger.refresh()
        gsap.set('main', {
          clearProps: 'all',
        })
      },
    })

    tl.to(
      'main',
      {
        xPercent: -100,
        duration: 0.75,
        ease: 'power3.inOut',
      },
      0,
    )

    tl.to(
      '#early-access',
      {
        xPercent: -100,
        duration: 0.75,
        ease: 'power3.inOut',
      },
      0,
    )

    tl.to(
      ['.nav-link'],
      {
        y: '-300%',
        stagger: {
          each: 0.05,
          from: 'end',
        },
        duration: 0.2,
        ease: 'power2.in',
      },
      0,
    )

    setTimeline(tl)
  }, [setTimeline])

  useEffect(() => {
    if (timeline) {
      if (open) {
        ScrollSmoother.get()?.paused(true)
        timeline.play()
      } else {
        timeline.reverse()
      }
    }
  }, [open, timeline])

  return <EarlyAccessContext.Provider value={values}>{children}</EarlyAccessContext.Provider>
}
