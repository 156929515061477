import { useLocation } from '@reach/router'
import Header from 'components/Header'
import Preloader from 'components/Preloader'
import { useLayoutProps } from 'contexts/LayoutContext'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import ClientOnly from 'library/ClientOnly'
import { isBrowser } from 'library/functions'
import { useBackButton } from 'library/Loader/TransitionUtils'
import { useTrackPageReady } from 'library/pageReady'
import Scroll from 'library/Scroll'
import useCSSHeightVariables from 'library/useCssHeightVariables'
import useTrackFrameTime from 'library/useTrackFrameTime'
import { lazy, startTransition, Suspense, useEffect, useRef, useState } from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'
import colors from 'styles/colors'

const Transition = lazy(() => import('components/Transition'))

const Footer = lazy(() => import('components/Footer'))
const EarlyAccess = lazy(() => import('./EarlyAccess'))
const Sky = lazy(() => import('./Sky'))

interface LayoutProps {
  children: React.ReactNode
  background?: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
  const { background } = useLayoutProps()
  useTrackPageReady()
  useBackButton()
  useCSSHeightVariables()
  useTrackFrameTime()

  const mainRef = useRef<HTMLElement | null>(null)
  const [mainHeight, setMainHeight] = useState<string | number>(99_999)
  const [showHeader, setShowHeader] = useState(true)

  const { pathname } = useLocation()

  useEffect(() => {
    if (isBrowser()) {
      const reload = () => {
        window.location.reload()
      }

      window.addEventListener('orientationchange', reload)

      return () => {
        window.removeEventListener('orientationchange', reload)
      }
    }
  })

  useEffect(() => {
    startTransition(() => {
      setMainHeight('auto')
    })

    const onScroll = () => {
      const scroller = ScrollSmoother.get()
      const rect = mainRef.current?.getBoundingClientRect()
      if (scroller && rect && scroller.scrollTop() >= rect.height - window.innerHeight) {
        window.analytics?.track?.('scrolled_to_bottom', {
          utm_source: window.utmSource,
          utm_medium: window.utmMedium,
          utm_campaign: window.utmCampaign,
        })
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  useEffect(() => {
    if (pathname.includes('/launch') || pathname.includes('/get-started')) {
      setShowHeader(false)
    }
  }, [pathname])

  return (
    <Wrapper $background={!pathname.includes('/launch')}>
      <GlobalStyle />
      {!pathname.includes('/launch') && (
        <Suspense>
          <ClientOnly>{background ?? <Sky />}</ClientOnly>
        </Suspense>
      )}
      {!pathname.includes('/launch') && <Preloader />}
      {showHeader && (
        <Suspense>
          <Transition />
        </Suspense>
      )}
      {showHeader && <Header />}
      <StyledScroll className="scroll-div">
        <Main
          ref={mainRef}
          style={{
            height: mainHeight, // used for consistent measuring, see preloader.tsx
          }}
        >
          {children}
          {showHeader && (
            <Suspense>
              <Footer />
            </Suspense>
          )}
        </Main>
      </StyledScroll>
      {showHeader && (
        <Suspense>
          <EarlyAccess />
        </Suspense>
      )}
    </Wrapper>
  )
}

const globalCss = css`
  body {
    color: ${colors.primaryBlack};
  }

  * {
    /* need this so that fonts match figma */
    text-rendering: geometricprecision;
  }

  /* fixes for visbug */
  vis-bug {
    position: fixed;
    z-index: var(--layer-1);
  }

  visbug-metatip,
  visbug-handles,
  visbug-ally {
    position: absolute;
    z-index: var(--layer-top);
  }

  /** restore default focus states for elements that need them */
  *:focus-visible {
    outline: 2px solid ${colors.primaryBlue};
  }
`

const GlobalStyle = createGlobalStyle`${globalCss}`

const Wrapper = styled.div<{ $background: boolean }>`
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  ${({ $background }) => css`
    ${$background && 'background: #d7e4ee;'}
  `}
`

const StyledScroll = styled(Scroll)`
  position: relative;
  z-index: 1;
  width: 100%;
`

const Main = styled.main`
  overflow: hidden;
  position: relative;
  width: 100%;

  > div {
    margin-top: -1px;
  }
`
