import { isBrowser, pathnameMatches } from 'library/functions'
import { createContext, startTransition, useContext, useEffect, useState } from 'react'

const Context = createContext(false)

export function WrapTwist({ children }: { children: React.ReactNode }) {
  return <Context.Provider value>{children}</Context.Provider>
}

export const useTwist = () => {
  const contextValue = useContext(Context)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    startTransition(() => {
      if (isBrowser()) setIsClient(true)
    })
  }, [])

  const fromURL = isClient ? pathnameMatches('/partners/twist', window.location.pathname) : false

  /**
   * if either the URL matches or the context value matches, we are in JPMC mode
   * using a dual approach like this allows us to use the context value in SSR,
   * while falling back to the URL in the client if needed
   */
  return fromURL || contextValue
}
